import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'
import Section from '../../../components/dai/Section'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import RoadColumn from '../../../components/dai/RoadColumn'
import { BoldText } from '../../../components/dai/BoldText'
import Hero from '../../../components/dai/Hero'
import LinkCTA from '../../../components/dai/LinkCTA'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'

const MaxImage = styled.div`
  max-width: 280px;
`

export default function KeyInsights() {
  return (
    <LayoutDAI showKeyInsights pagePath={{ parent: 'key-insights', child: '' }}>
      <SEO
        title="3 Key Insights | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/key-insights/"
        desc="Our scan revealed a number of significant accessibility barriers that are present on most sites. Here are the top 3 insights we found."
        bannerUrl="https://images.prismic.io/audioeye-web/8ebba313-cd55-4712-9dba-6d77b3a88255_key-insights.png?auto=compress,format"
        imageAlt="Stylized map illustrating inaccessible experiences are similar to directions that reroutes rather than giving you the direct route."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/key-insights/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/key-insights.png"
            alt="Stylized map illustrating inaccessible experiences are similar to directions that reroutes rather than giving you the direct route."
          />
        }
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          KEY INSIGHTS
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Key Insights">
          Digital accessibility is still a challenge for the world’s leading brands.
        </Heading1>
        <Space height={32} />
        <Text>
          Given how much emphasis is placed on the user experience today, it’s surprising more attention isn’t paid to
          the accessibility barriers impacting the{' '}
          <BoldText>1.3 billion people globally who live with a disability</BoldText>.
        </Text>
        <Space height={32} />
        <Text>
          Even when brands try to build inclusive websites, accessibility issues can slip through the cracks. And many
          of these barriers can be so disruptive to the user experience that people with disabilities are forced to
          abandon their task — and try to complete it elsewhere.
        </Text>
      </Hero>
      <Section paddingTop="md" constraint="lg">
        <Columns>
          <Column size={16} tabletSize={24}>
            <Text largeCapsInter color="orange900">
              INDEX AVERAGE
            </Text>
            <Space height={24} />
            <Heading2 heading2 alt>
              Digital Accessibility Index
            </Heading2>
            <Space height={32} />
            <Text>
              After scanning more than 2 million pages, we found that 100% of the pages had at least one accessibility
              error — and the average page had{' '}
              <BoldText>
                37 unique elements (such as images or links) that failed one of the Web Content Accessibility Guidelines
                (WCAG) success criteria
              </BoldText>
              , the international standard for web accessibility.
            </Text>
            <Space height={32} />
            <Text>
              We used the average number of failed elements per page as the basis of our{' '}
              <BoldText>Index Average</BoldText>. It helps people gauge the relative accessibility of a page, based on
              the number of errors a user might encounter.
            </Text>
            <Space height={32} />
            <Text>
              As a business, your goal should be to get this number as close to zero as possible — because that
              represents the fewest number of accessibility errors.
            </Text>
          </Column>
          <Column size={8} tabletSize={24} justifyCenter>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/content/index-average-dashboard.png"
                alt="A car gauge representing an index score of 37. The gauge shows that an index score of 37 is a serious problem."
              />
            </MaxImage>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" innerConstraint="md">
        <Text largeCapsInter color="orange900">
          TOP 3 INSIGHTS
        </Text>
        <Space height={24} />
        <Heading2 heading2 alt>
          The biggest roadblocks to digital experiences that work for all
        </Heading2>
        <Space height={32} />
        <Text>
          Our automated scan revealed a number of significant barriers that are present on almost every site — and can
          make it harder for people with disabilities to reach their digital destination.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg">
        <Columns noSpaceBetween gap={12} orderedList>
          <Column size={8} tabletSize={24} listItem>
            <RoadColumn
              noLeftBorder
              cta={
                <LinkCTA
                  aria-label="Learn tips on image accessibility"
                  text="Tips on image accessibility"
                  to="/digital-accessibility-index/key-insights/image-accessibility-tips/"
                />
              }
            >
              <Text largeCapsInter color="orange900">
                INSIGHT #1
              </Text>
              <Space height={12} />
              <Text
                heading2
                className="asH4"
                color="orange900"
                aria-label="56% of images are not accessible to people with visual impairments."
              >
                <span className="large-stat">56%</span> of images are not accessible to people with visual impairments.
              </Text>
              <Space height={32} />
              <Text>
                The world’s leading brands are increasingly turning to visual content to highlight their products and
                services — but inaccessible images are creating problems for the million-plus people in the United
                States who are blind.
              </Text>
              <Space height={32} />
              <Text>
                After scanning more than 32 million images, we found that{' '}
                <BoldText>56% of images were not accessible to people with visual impairments.</BoldText>
              </Text>
            </RoadColumn>
          </Column>
          <Column size={8} tabletSize={24} listItem>
            <RoadColumn
              cta={
                <LinkCTA
                  aria-label="Learn tips on link accessibility"
                  text="Tips on link accessibility"
                  to="/digital-accessibility-index/key-insights/link-accessibility-tips/"
                />
              }
            >
              <Text largeCapsInter color="orange900">
                INSIGHT #2
              </Text>
              <Space height={12} />
              <Text
                heading2
                className="asH4"
                color="orange900"
                aria-label="64% of pages have links that are not clear to people with disabilities."
              >
                <span className="large-stat">64%</span> of pages have links that are not clear to people with
                disabilities.
              </Text>
              <Space height={32} />
              <Text>
                We all rely on links to help us reach the pages and content we want, but vague or non-descriptive links
                can make browsing websites a challenge for people with visual or cognitive impairments.
              </Text>
              <Space height={32} />
              <Text>
                After scanning more than 357 million links, we found that{' '}
                <BoldText>64% of sites had at least one page with an inaccessible link.</BoldText>
              </Text>
            </RoadColumn>
          </Column>
          <Column size={8} tabletSize={24} listItem>
            <RoadColumn
              noRightBorder
              cta={
                <LinkCTA
                  aria-label="Learn tips on form accessibility"
                  text="Tips on form accessibility"
                  to="/digital-accessibility-index/key-insights/form-accessibility-tips/"
                />
              }
            >
              <Text largeCapsInter color="orange900">
                INSIGHT #3
              </Text>
              <Space height={12} />
              <Text heading2 className="asH4" color="orange900">
                <span className="large-stat">1 in 4</span> forms was missing descriptive labels for people with
                disabilities.
              </Text>
              <Space height={32} />
              <Text>
                From checkout flows to lead generation campaigns, the world’s leading brands spend a lot of time
                figuring out how to reduce form friction — and get more people to take the action they want.
              </Text>
              <Space height={32} />
              <Text>
                After scanning nearly 2 million forms, we found that{' '}
                <BoldText>25% were missing descriptive labels for people with disabilities.</BoldText>
              </Text>
            </RoadColumn>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg">
        <Quote
          quoteText="“I run into accessibility barriers every day. It’s frustrating. I have to ask myself ‘How am I going to get around this?’ on a regular basis.”"
          author="Chris Preiman"
          title="AudioEye A11iance Member & Internet Security Professional"
        />
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/executive-summary/"
        previousPageText="Executive Summary"
        nextPageLink="/digital-accessibility-index/key-insights/image-accessibility-tips/"
        nextPageText="Image Accessibility Tips"
      />
    </LayoutDAI>
  )
}
