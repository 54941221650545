/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'

import Space from '../common/Space'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // needed specific to these road columns based on the content
  @media (min-width: 1200px) and (max-width: 1280px) {
    padding: ${props => (props.noLeftBorder ? '0 17px 0 0' : '0 17px')} !important;
  }

  // needed specific to these road columns based on the content
  @media (min-width: 769px) and (max-width: 860px) {
    padding: ${props => (props.noLeftBorder ? '0 17px 0 0' : '0 17px')} !important;
  }

  @media (max-width: 768px) {
    ${props => !props.noRightBorder && `border-bottom: 3px solid ${props.theme.color.orange500};`}
    ${props =>
      !props.noLeftBorder &&
      `
        background-image: linear-gradient(to right, #FFD088 55%, rgba(255, 208, 136, 0) 0%);
        background-position: top;
        background-size: 40px 4px;
        background-repeat: repeat-x;
    `}
    padding-bottom: 32px;
    padding-top: 32px;
  }

  @media (min-width: 769px) {
    ${props => !props.noRightBorder && `border-right: 4px solid ${props.theme.color.orange500};`}

    ${props =>
      !props.noLeftBorder &&
      `
        background-image: linear-gradient(to bottom, #FFD088 55%, rgba(255, 208, 136, 0) 0%);
        background-position: left;
        background-size: 4px 40px;
        background-repeat: repeat-y;
    `}
    padding: ${props => (props.noLeftBorder ? '0 32px 0 0' : '0 32px')};
  }
`

const RoadColumn = ({ children, noLeftBorder, noRightBorder, cta }) => (
  <Wrapper noLeftBorder={noLeftBorder} noRightBorder={noRightBorder}>
    <div>{children}</div>
    <div>
      <Space height={32} />
      {cta}
    </div>
  </Wrapper>
)

export default RoadColumn
